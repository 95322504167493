import { JSEncrypt } from "jsencrypt";
import lodash from "lodash";
import axios from "axios";

import { request } from "./services/utils";
import pubKey from "./pubKey.js";

/// FEDERATED /////////////////////////////
import AWS from "aws-sdk";
import { Auth } from "@aws-amplify/auth";
import Storage from "@aws-amplify/storage";
const Chime = require("aws-sdk/clients/chime");
///////////////////////////////////////////


// Care path kit related API
export * from "./services/care-path";

export const localRegion = 'us-west-2'
// export const localRegion = 'cn-northwest-1'


function getHostname(){
  let host = window.location.hostname;
  if(host === "localhost"){
    // host = `https://terminal-dev.humanlongevity.com`;
    host = `https://terminal-test.humanlongevity.com`;
    // host = `https://terminal.humanlongevity.com`;
  }else{
    host = "https://" + host
  }
  host += /#/
  return host;
}







// compose API address for US or CN region
// API from sl-js-portal project

// var localAPI = "https://hli-portal-api-dev.hli.io"; // DEV
var localAPI = "https://hli-portal-api-test.hli.io"; // TEST
// var localAPI = "https://hli-portal-api.hli.io"; // PROD, Local access is very dangerous and requires great care
if (localRegion === "cn-northwest-1") {
  localAPI = "https://apollo8-portal-api-dev.apollo8health.com"; // DEV
  // localAPI = "https://apollo8-portal-api.apollo8health.com"; // PORD, Local access is very dangerous and requires great care
}
const DOMAIN = ["localhost", "10.0.0.51", "10.0.0.63", "10.11.117.104"].includes(
  lodash.get(window, "location.hostname", "")
)
  ? localAPI
  : "https://hli-portal-api.hli.io"; // gulp will change this to a live env variable

// API from sl-js-portal2 project
var temp = DOMAIN.split("-");
temp[1] = temp[1] + "-2";
const DOMAIN2 = temp.join("-"); //https://apollo8-portal-2-api-dev.apollo8health.com

export const ROUTES = {
  POST_LOGIN_CN: "/loginCn",
  POST_LOGIN_CN_S: "/loginCn?s=true",
  SEND_SNS: "/sendSNS",
  POST_NPS: "/addNpsResults",
  GET_NPS: "/findNpsResults",
  GET_BATCH_PRESIGNED_URL: "/getBatchUrl",
  GET_THIRD_PARTY: "/getThirdParty",
  POST_SURVEY: "/addSatisfactorySurvey",
  GET_SURVEY: "/findSatisfactorySurvey",
  PMH_CACHE: "/pmh",
  POST_PMH: "/pmhPhysician",
  AUTO_RELEASE: "/autoRelease",
  RELEASE_RECORD: "/release_record",
  POST_LOGIN: "/login",
  POST_VERIFY: "/verify",
  POST_VERIFY_ATTEMPTS: "/verifyattempts",
  POST_PATIENT_LIST: "/patientlist",
  POST_PATIENT_REPORTS: "/reportlist",
  POST_REPORT_PDF: "/report",
  POST_FORGOT_PASSWORD: "/forgotpassword",
  POST_SET_PASSWORD: "/setpassword",
  POST_SET_PASSWORD_S: "/setpassword?s=true",
  POST_SET_PASSWORD_2: "/setpassword2?s=true",
  LOGOUT: "/logout",
  GET_USER_METADATA: "/usermetadata",
  REGISTER: "/register",
  UPLOAD_REPORT: "/reportupload",
  RELEASE_REPORT: "/reportrelease",
  UPDATE_PROFILE: "/profileupdate",
  GET_VERIFICATION_PENDING: "/verificationpending",
  POST_VERIFICATION_ATTEMPTS_RESET: "/verificationattemptsreset",
  POST_LIST_DOCUMENTS: "/listdocuments",
  POST_GET_DOCUMENT: "/getdocument",
  GET_AUTH_TOKEN: "/getauthtoken",
  RECENT_SEARCH: "/recentsearch",
  GET_RECENT_SEARCH: "/getrecentsearch",
  DELETE_RECENT_SEARCH: "/deleterecentsearch",
  FAVORITE_SEARCH: "/favoritesearch",
  GET_FAVORITE_SEARCH: "/getfavoritesearch",
  DELETE_FAVORITE_SEARCH: "/deletefavoritesearch",
  SEARCH_PATIENT_ORDERS: "/searchpatientorders",
  SEARCH_PATIENT_ORDERS_ES: "/searchPatientOrderEs",
  CLINICIAN: "/clinician",
  CLINICIANS: "/clinicians",
  PARTNERS: "/partners",
  MEMBERSHIPS: "/memberships",
  GET_REPORT_CATEGORY: "/getreportcategory",
  GET_PATIENT_REPORT: "/getreport",
  PMH_VERIFY_FIELDS: "/pmhverifyfields",
  ADD_REPORT: "/addreport",
  DASHBOARD_UPLOAD_REPORT: "/dashboarduploadreport",
  DASHBOARD_LIST_REPORT: "/dashboardlistreport",
  DASHBOARD_GET_REPORT: "/dashboardgetreport",
  DASHBOARD_RENAME_REPORT: "/dashboardrenamereport",
  DASHBOARD_DELETE_REPORT: "/dashboarddeletereport",
  CONSENT_ADD_REPORT: "/consentaddreport",
  GET_HEALTH_CATEGORY: "/gethealthcategory",
  SUGGESTION: "/suggestion",
  GET_SUGGESTION: "/getsuggestion",
  NOTE: "/note",
  GET_NOTE: "/getnote",
  FOLLOW_UP: "/followup",
  GET_FOLLOW_UP: "/getfollowup",
  GET_TEST_RESULTS_DETAILS: "/gettestresultsdetails",
  GET_GENE_ANALYSES: "/getgeneanalyses",
  GET_ROR: "/ror",
  ADD_ROR: "/ror",
  REFRESH_ROR: "/rorRefresh",
  GET_CLASSIFICATION: "/getClassification",
  GET_ROR_NAME: "/getRorNameList",
  GET_ROR_EDIT: "/getRorEditStatus",

  CALC_MESA: "/rorCalcMesa",
  GET_LONGEVITY_REPORT_GROUPS: "/report_groups",
  SUBJECT: "/subject",
  SUBJECT_ORDERS: "/subjectorders",
  PUT_UPDATE_MSG_CHANNELS: "/update_msg_channels",
  PUT_DELETE_MSG_CHANNELS: "/delete_msg_channels",
  GET_MSG_CHANNELS: "/get_msg_channels",
  POST_ADD_MESSAGING_USER: "/add_messaging_user",
  PUT_UPDATE_MESSAGING_USER: "/update_messaging_user",
  GET_FIND_MESSAGING_USER: "/find_messaging_user",
  GET_MESSAGING_USER: "/get_messaging_user",
  GET_CRITICAL_VALUE_REPORT: "/querycriticalvaluereport",
  GET_MATRIX: "/longevityMatrix",
  UPDATE_MATRIX: "/longevityMatrixUpdate",
  CHECK_IN_WHITE: "/checkRorWhitelist",
  POST_SEND_NOTIFICATIONS: "/send_notifications",
  MY_CLIENT_LIST: "/myClientList",
  MY_CLIENT_FILTER_ITEM_LIST: "/myClientFilterItemList",
  QUERY_STATISTICS_REPROT: "/querystatisticsreport",
  QUERY_STATISTICS_REPROT_DETAIL: "/querystatisticsreportdetail",
  QUERY_STATISTICS_WILL_OVERDUE: "/querystatisticswilloverdue",
  GET_PMH_MEDICINES: "/pmhMedicines",
  CONSENT_FORM_RECORD_LIST: "/consentFormRecordList",
  GET_WGS_UPDATE: "/wgsupdate",
  CARE_PATH: "/carePath",
  GET_SEARCH_BIOMARKER: "/searchBioMarkerCategory"
};

export const URLS = {
  POST_LOGIN: DOMAIN + ROUTES.POST_LOGIN,
  SEND_SNS: DOMAIN + ROUTES.SEND_SNS,
  GET_SURVEY: DOMAIN2 + ROUTES.GET_SURVEY,
  POST_SURVEY: DOMAIN2 + ROUTES.POST_SURVEY,
  POST_NPS: DOMAIN2 + ROUTES.POST_NPS,
  GET_NPS: DOMAIN2 + ROUTES.GET_NPS,
  GET_THIRD_PARTY: DOMAIN2 + ROUTES.GET_THIRD_PARTY,
  GET_BATCH_PRESIGNED_URL: DOMAIN2 + ROUTES.GET_BATCH_PRESIGNED_URL,
  POST_PMH: DOMAIN2 + ROUTES.POST_PMH,
  PMH_CACHE: DOMAIN2 + ROUTES.PMH_CACHE,
  AUTO_RELEASE: DOMAIN2 + ROUTES.AUTO_RELEASE,
  GET_ROR: DOMAIN2 + ROUTES.GET_ROR,
  ADD_ROR: DOMAIN2 + ROUTES.ADD_ROR,
  REFRESH_ROR: DOMAIN2 + ROUTES.REFRESH_ROR,
  GET_CLASSIFICATION: DOMAIN2 + ROUTES.GET_CLASSIFICATION,
  GET_ROR_NAME: DOMAIN2 + ROUTES.GET_ROR_NAME,
  GET_ROR_EDIT: DOMAIN2 + ROUTES.GET_ROR_EDIT,
  CALC_MESA: DOMAIN2 + ROUTES.CALC_MESA,
  GET_LONGEVITY_REPORT_GROUPS: DOMAIN2 + ROUTES.GET_LONGEVITY_REPORT_GROUPS,
  RELEASE_RECORD: DOMAIN + ROUTES.RELEASE_RECORD,
  POST_LOGIN_CN: DOMAIN + ROUTES.POST_LOGIN_CN,
  POST_LOGIN_CN_S: DOMAIN + ROUTES.POST_LOGIN_CN_S,
  POST_VERIFY: DOMAIN + ROUTES.POST_VERIFY,
  POST_VERIFY_ATTEMPTS: DOMAIN + ROUTES.POST_VERIFY_ATTEMPTS,
  POST_PATIENT_LIST: DOMAIN + ROUTES.POST_PATIENT_LIST,
  POST_PATIENT_REPORTS: DOMAIN + ROUTES.POST_PATIENT_REPORTS,
  POST_REPORT_PDF: DOMAIN + ROUTES.POST_REPORT_PDF,
  POST_FORGOT_PASSWORD: DOMAIN + ROUTES.POST_FORGOT_PASSWORD,
  POST_SET_PASSWORD: DOMAIN + ROUTES.POST_SET_PASSWORD,
  POST_SET_PASSWORD_S: DOMAIN + ROUTES.POST_SET_PASSWORD_S,
  POST_SET_PASSWORD_2: DOMAIN + ROUTES.POST_SET_PASSWORD_2,
  LOGOUT: DOMAIN + ROUTES.LOGOUT,
  GET_USER_METADATA: DOMAIN + ROUTES.GET_USER_METADATA,
  REGISTER: DOMAIN + ROUTES.REGISTER,
  UPLOAD_REPORT: DOMAIN + ROUTES.UPLOAD_REPORT,
  RELEASE_REPORT: DOMAIN + ROUTES.RELEASE_REPORT,
  UPDATE_PROFILE: DOMAIN + ROUTES.UPDATE_PROFILE,
  GET_VERIFICATION_PENDING: DOMAIN + ROUTES.GET_VERIFICATION_PENDING,
  POST_VERIFICATION_ATTEMPTS_RESET: DOMAIN + ROUTES.POST_VERIFICATION_ATTEMPTS_RESET,
  POST_LIST_DOCUMENTS: DOMAIN + ROUTES.POST_LIST_DOCUMENTS,
  POST_GET_DOCUMENT: DOMAIN + ROUTES.POST_GET_DOCUMENT,
  GET_AUTH_TOKEN: DOMAIN + ROUTES.GET_AUTH_TOKEN,
  RECENT_SEARCH: DOMAIN + ROUTES.RECENT_SEARCH,
  GET_RECENT_SEARCH: DOMAIN + ROUTES.GET_RECENT_SEARCH,
  DELETE_RECENT_SEARCH: DOMAIN + ROUTES.DELETE_RECENT_SEARCH,
  FAVORITE_SEARCH: DOMAIN + ROUTES.FAVORITE_SEARCH,
  GET_FAVORITE_SEARCH: DOMAIN + ROUTES.GET_FAVORITE_SEARCH,
  DELETE_FAVORITE_SEARCH: DOMAIN + ROUTES.DELETE_FAVORITE_SEARCH,
  SEARCH_PATIENT_ORDERS: DOMAIN + ROUTES.SEARCH_PATIENT_ORDERS,
  SEARCH_PATIENT_ORDERS_ES: DOMAIN2 + ROUTES.SEARCH_PATIENT_ORDERS_ES,
  CLINICIAN: DOMAIN + ROUTES.CLINICIAN,
  CLINICIANS: DOMAIN + ROUTES.CLINICIANS,
  PARTNERS: DOMAIN + ROUTES.PARTNERS,
  MEMBERSHIPS: DOMAIN + ROUTES.MEMBERSHIPS,
  GET_REPORT_CATEGORY: DOMAIN + ROUTES.GET_REPORT_CATEGORY,
  GET_PATIENT_REPORT: DOMAIN + ROUTES.GET_PATIENT_REPORT,
  PMH_VERIFY_FIELDS: DOMAIN + ROUTES.PMH_VERIFY_FIELDS,
  ADD_REPORT: DOMAIN + ROUTES.ADD_REPORT,
  DASHBOARD_UPLOAD_REPORT: DOMAIN + ROUTES.DASHBOARD_UPLOAD_REPORT,
  DASHBOARD_LIST_REPORT: DOMAIN + ROUTES.DASHBOARD_LIST_REPORT,
  DASHBOARD_GET_REPORT: DOMAIN + ROUTES.DASHBOARD_GET_REPORT,
  DASHBOARD_DELETE_REPORT: DOMAIN + ROUTES.DASHBOARD_DELETE_REPORT,
  DASHBOARD_RENAME_REPORT: DOMAIN + ROUTES.DASHBOARD_RENAME_REPORT,
  CONSENT_ADD_REPORT: DOMAIN + ROUTES.CONSENT_ADD_REPORT,
  GET_HEALTH_CATEGORY: DOMAIN + ROUTES.GET_HEALTH_CATEGORY,
  SUGGESTION: DOMAIN + ROUTES.SUGGESTION,
  GET_SUGGESTION: DOMAIN + ROUTES.GET_SUGGESTION,
  NOTE: DOMAIN + ROUTES.NOTE,
  GET_NOTE: DOMAIN + ROUTES.GET_NOTE,
  FOLLOW_UP: DOMAIN + ROUTES.FOLLOW_UP,
  GET_FOLLOW_UP: DOMAIN + ROUTES.GET_FOLLOW_UP,
  GET_TEST_RESULTS_DETAILS: DOMAIN + ROUTES.GET_TEST_RESULTS_DETAILS,
  GET_GENE_ANALYSES: DOMAIN + ROUTES.GET_GENE_ANALYSES,
  SUBJECT: DOMAIN + ROUTES.SUBJECT,
  SUBJECT_ORDERS: DOMAIN + ROUTES.SUBJECT_ORDERS,
  PUT_UPDATE_MSG_CHANNELS: DOMAIN2 + ROUTES.PUT_UPDATE_MSG_CHANNELS,
  PUT_DELETE_MSG_CHANNELS: DOMAIN2 + ROUTES.PUT_DELETE_MSG_CHANNELS,
  GET_MSG_CHANNELS: DOMAIN2 + ROUTES.GET_MSG_CHANNELS,
  POST_ADD_MESSAGING_USER: DOMAIN + ROUTES.POST_ADD_MESSAGING_USER,
  PUT_UPDATE_MESSAGING_USER: DOMAIN + ROUTES.PUT_UPDATE_MESSAGING_USER,
  GET_FIND_MESSAGING_USER: DOMAIN + ROUTES.GET_FIND_MESSAGING_USER,
  GET_MESSAGING_USER: DOMAIN + ROUTES.GET_MESSAGING_USER,
  GET_CRITICAL_VALUE_REPORT: DOMAIN2 + ROUTES.GET_CRITICAL_VALUE_REPORT,
  CHECK_IN_WHITE: DOMAIN2 + ROUTES.CHECK_IN_WHITE,
  GET_MATRIX_DATA: DOMAIN2 + ROUTES.GET_MATRIX,
  UPDATE_MATRIX_DATA: DOMAIN2 + ROUTES.UPDATE_MATRIX,
  POST_SEND_NOTIFICATIONS: DOMAIN2 + ROUTES.POST_SEND_NOTIFICATIONS,
  MY_CLIENT_LIST: DOMAIN2 + ROUTES.MY_CLIENT_LIST,
  MY_CLIENT_FILTER_ITEM_LIST: DOMAIN2 + ROUTES.MY_CLIENT_FILTER_ITEM_LIST,
  QUERY_STATISTICS_REPROT: DOMAIN2 + ROUTES.QUERY_STATISTICS_REPROT,
  QUERY_STATISTICS_REPROT_DETAIL: DOMAIN2 + ROUTES.QUERY_STATISTICS_REPROT_DETAIL,
  QUERY_STATISTICS_WILL_OVERDUE: DOMAIN2 + ROUTES.QUERY_STATISTICS_WILL_OVERDUE,
  GET_PMH_MEDICINES: DOMAIN2 + ROUTES.GET_PMH_MEDICINES,
  CONSENT_FORM_RECORD_LIST: DOMAIN2 + ROUTES.CONSENT_FORM_RECORD_LIST,
  GET_WGS_UPDATE: DOMAIN2 + ROUTES.GET_WGS_UPDATE,
  CARE_PATH: DOMAIN2 + ROUTES.CARE_PATH,
  GET_SEARCH_BIOMARKER: DOMAIN2 + ROUTES.GET_SEARCH_BIOMARKER
};


/// APIs

export const releaseRecord = (payload) => {
  return request(URLS.RELEASE_RECORD, {
    method: "post",
    data: payload,
  });
};

export const postPMHCache = (hli_subject_id, content, physicianName, update_time) => {
  return request(URLS.PMH_CACHE, {
    method: "post",
    data: {
      hli_subject_id: hli_subject_id,
      physician_name: physicianName,
      content: content,
      update_time: update_time,
    },
  });
};

export const postSurvey = (hli_subject_id, visit_date, content, phone) => {
  return request(URLS.POST_SURVEY, {
    method: "post",
    data: {
      phone: phone,
      data: {
        hli_subject_id: hli_subject_id,
        visit_date: visit_date,
        contents: content,
      },
    },
  });
};

export const getSurvey = (hli_subject_id, visit_date, date_from, date_to) => {
  var params = {};

  params.hli_subject_id = hli_subject_id;
  if (visit_date) {
    params.visit_date = visit_date;
  }
  if (date_from) {
    params.date_from = date_from;
  }
  if (date_to) {
    params.date_to = date_to;
  }

  return request(`${URLS.GET_SURVEY}`, {
    params,
  });
};

export const getNPS = (date_from = null, date_to = null) => {
  var params = { placeHolder: 1 };
  console.log("a", date_from);
  params.date_from = date_from;

  params.date_to = date_to;

  console.log("param", params);
  return request(`${URLS.GET_NPS}`, {
    params,
  });
};

export const getThirdParty = (thirdPartyName) => {
  console.log("name", thirdPartyName);
  var params = { placeHolder: 1, questionareNumber: thirdPartyName };
  params["questionareNumber"] = thirdPartyName;

  return request(`${URLS.GET_THIRD_PARTY}`, {
    params,
  });
};

export const getPMHCache = (hli_subject_id, physicianName) => {
  var params = {};
  params.hli_subject_id = hli_subject_id;
  params.physician_name = physicianName;

  return request(`${URLS.PMH_CACHE}`, {
    params,
  });
};

export const postPMH = (hli_subject_id, content, PhysicianName) => {
  return request(URLS.POST_PMH, {
    method: "post",
    data: {
      hli_subject_id: hli_subject_id,
      data: {
        content: { data: { content } },
        submitted_by: PhysicianName,
      },
    },
  });
};

export const getPMH = (hli_subject_id) => {
  var params = {};
  params.hli_subject_id = hli_subject_id;

  return request(`${URLS.POST_PMH}`, {
    params,
  });
};

export const getSearchBiomarker = (hli_subject_id) => {
  var params = {delete_redis_data:false};

  return request(`${URLS.GET_SEARCH_BIOMARKER}`, {
    params,
  });
};

export const sendAutoRelease = (hli_subject_id, release_state) => {
  return request(URLS.AUTO_RELEASE, {
    method: "post",
    data: {
      hli_subject_id: hli_subject_id,
      release_state: release_state,
    },
  });
};

export const getAutoRelease = (hli_subject_id) => {
  var params = {};
  params.hli_subject_id = hli_subject_id;

  return request(`${URLS.AUTO_RELEASE}`, {
    params,
  });
};

export const sendSNS = (feedback) => {
  return request(URLS.SEND_SNS, {
    method: "post",
    data: {
      feedback,
    },
  });
};

export const login = (username, password, phone, sms) => {
  if (phone) {
    const data = {
      phone,
      sms,
      password,
    };
    const encryptor = new JSEncrypt();
    encryptor.setPublicKey(pubKey);
    const DataStr = JSON.stringify(data);
    const rData = encryptor.encrypt(DataStr);
    return request(URLS.POST_LOGIN_CN_S, {
      method: "post",
      data: rData,
    });
  } else {
    return request(URLS.POST_LOGIN, {
      method: "post",
      data: {
        username,
        password,
      },
    });
  }
};

export const refreshToken = () => {
  refreshToken_()
    .then((res) => {
      // console.log("refreshToken_ res.data: ", res.data);
      if (res.data.auth0_refresh_token) {
        // console.log("refreshed access_token: ", res.data.access_token)
        // console.log("auth0Cookie auth0_access_token: ", readCookie("auth0Cookie"))
        // console.log("refreshed   auth0_access_token: ", res.data.auth0_access_token)
        // console.log("refreshed auth0_refresh_token: ", res.data.auth0_refresh_token)
        // console.log("refreshed expires_at: ", res.data.expires_at);
        console.log("are access_tokens same: ", readCookie("auth0Cookie") === res.data.access_token );

        document.cookie = `authCookie=${res.data.access_token}`;
        document.cookie = `auth0Cookie=${res.data.auth0_access_token}`;
        document.cookie = `auth0RefTok=${res.data.auth0_refresh_token}`;
        document.cookie = `auth0ExpiresAt=${res.data.expires_at}`;

        //temp commented, Amplify has not been configured correctly.
        // NotAuthorizedException: Invalid login token. Missing a required claim: aud
        // federatedSignIn();
      }
    })
    .catch((err) => {
      console.log("refreshToken err: ", err);
      err && console.log("refreshToken err: ", err.message);
    });
  return true;
};

const refreshToken_ = () => {

  // console.log("refreshToken_ auth0RefTok: ", readCookie("auth0RefTok"))
  return request(URLS.POST_LOGIN, {
    method: "post",
    data: {
      username: localStorage.getItem("lsEmail"),
      access_token: readCookie("auth0Cookie"),
      refresh_token: readCookie("auth0RefTok")
    },
  });
};

export const verify = (lastname, date_of_birth, verification_uuid, auth_email_status, email) => {
  return request(URLS.POST_VERIFY, {
    method: "post",
    data: {
      lastname,
      date_of_birth,
      verification_uuid,
      auth_email_status,
      email: email
    },
  });
};

export const verifyAttempts = (verification_uuid) => {
  return request(URLS.POST_VERIFY_ATTEMPTS, {
    method: "post",
    data: {
      verification_uuid,
    },
  });
};

export const verificationPending = () => {
  return request(URLS.GET_VERIFICATION_PENDING);
};

export const verificationAttemptsReset = (verification_uuid) => {
  return request(URLS.POST_VERIFICATION_ATTEMPTS_RESET, {
    method: "post",
    data: {
      verification_uuid,
    },
  });
};

export const listDocuments = (hli_subject_id, vsee_id, viewDataset) => {
  var payload = {
    hli_subject_id,
    vsee_id,
  };
  if (viewDataset === true) {
    payload = {
      hli_subject_id,
      vsee_id,
      viewDataset,
      from: "terminal",
    };
  }
  return request(URLS.POST_LIST_DOCUMENTS, {
    method: "post",
    data: payload,
  });
};

export const getDocument = (bucket, key) => {
  return request(URLS.POST_GET_DOCUMENT, {
    method: "post",
    data: {
      bucket,
      key,
    },
  });
};

export const patientList = () => {
  return request(`${URLS.POST_PATIENT_LIST}`, {
    method: "get",
  });
};

export const patientReports = (hliSubjectID, patientEmail) => {
  return request(`${URLS.POST_PATIENT_REPORTS}`, {
    method: "post",
    data: {
      hli_subject_id: hliSubjectID,
      patient_email: patientEmail,
    },
  });
};

export const getReport = (reportId) => {
  return request(`${URLS.POST_REPORT_PDF}`, {
    method: "post",
    data: {
      id: reportId,
    },
  });
};

export const setPassword = (password, verification_uuid) => {
  const encryptor = new JSEncrypt();
  encryptor.setPublicKey(pubKey);
  const DataStr = JSON.stringify({
    password,
    verification_uuid,
  });
  const rData = encryptor.encrypt(DataStr);
  if (localRegion === "cn-northwest-1") {
    return request(URLS.POST_SET_PASSWORD_S, {
      method: "post",
      data: rData,
    });
  } else {
    return request(URLS.POST_SET_PASSWORD_2, {
      method: "post",
      data: rData,
      // data: {
      //   password,
      //   verification_uuid,
      // },
    });
  }
};

export const forgotPassword = (email, resetActually) => {
  return request(URLS.POST_FORGOT_PASSWORD, {
    method: "post",
    data: {
      email,
      resetActually,
      redirect_uri: getHostname()
    },
  });
};

export const logout = () => {
  return request(`${URLS.LOGOUT}`, {
    method: "get",
  });
};

export const getUserMetadata = () => {
  return request(`${URLS.GET_USER_METADATA}`, {
    method: "get",
  });
};

export const register = (userData) => {
  return request(`${URLS.REGISTER}`, {
    method: "post",
    data: userData,
  });
};

export const uploadReport = (data) => {
  return request(`${URLS.UPLOAD_REPORT}`, {
    method: "post",
    data: data,
  });
};

export const releaseReport = (reportData, release) => {
  return request(`${URLS.RELEASE_REPORT}`, {
    method: "post",
    data: {
      dataset: reportData,
      release,
    },
  });
};

export const updateProfile = (data) => {
  return request(`${URLS.UPDATE_PROFILE}`, {
    method: "post",
    data: data,
  });
};

export const dashboardUploadReport = (data) => {
  return request(`${URLS.DASHBOARD_UPLOAD_REPORT}`, {
    method: "post",
    data: data,
  });
};

export const dashboardListReport = (data) => {
  return request(`${URLS.DASHBOARD_LIST_REPORT}`, {
    method: "post",
    data: data,
  });
};

export const getBatchPresignedUrl = (data) => {
  return request(`${URLS.GET_BATCH_PRESIGNED_URL}`, {
    method: "post",
    data: data,
  });
};

export const dashboardGetReport = (data) => {
  return request(`${URLS.DASHBOARD_GET_REPORT}`, {
    method: "post",
    data: data,
  });
};

export const dashboardRenameReport = (data) => {
  return request(`${URLS.DASHBOARD_RENAME_REPORT}`, {
    method: "post",
    data: data,
  });
};

export const dashboardDeleteReport = (data, datasetId) => {
  return request(`${URLS.DASHBOARD_DELETE_REPORT}`, {
    method: "post",
    data: data,
  });
};

export const addReport = (data) => {
  return request(`${URLS.ADD_REPORT}`, {
    method: "post",
    data: data,
  });
};

export const consentAddReport = (data) => {
  return request(`${URLS.CONSENT_ADD_REPORT}`, {
    method: "post",
    data: data,
  });
};

export const getAuthToken = () => {
  return request(`${URLS.GET_AUTH_TOKEN}`, {
    method: "get",
  });
};

/////////////
// PRISM APIs
/////////////

export const getClinician = (hliClinicianId, idDetails) => {
  var params = {};
  params.hli_clinician_id = hliClinicianId;
  if (idDetails) params.details = "details";

  return request(`${URLS.CLINICIAN}`, {
    params,
  });
};
export const getAssignedPhysicians = (partnerCode, siteCode, licenseType) => {
  var params = {};
  params.hli_clinician_id = readCookie("hli_clinician_id");
  if (partnerCode) params.partner_code = partnerCode;
  if (siteCode) params.site_code = siteCode;
  if (licenseType) params.license_type = licenseType;

  return request(`${URLS.CLINICIANS}`, {
    params,
  });
};
export const getClinicLocations = (partnerCode, siteCode) => {
  var params = {};
  params.hli_clinician_id = readCookie("hli_clinician_id");
  if (partnerCode) params.partner_code = partnerCode;
  if (siteCode) params.site_code = siteCode;

  return request(`${URLS.PARTNERS}`, {
    params,
  });
};
export const getMemberships = () => {
  var params = {};
  params.hli_clinician_id = readCookie("hli_clinician_id");

  return request(`${URLS.MEMBERSHIPS}`, {
    params,
  });
};

export const getWgsUpdate = () => {
  var params = {};

  return request(`${URLS.GET_WGS_UPDATE}`, {
    params,
  });
};

export const pmhVerifyFields = (obj) => {
  var params = {};

  params.client_subject_id = obj.clientSubjectId;
  params.client_subject_id_type = obj.clientSubjectIdType;
  params.middle_name = obj.middleName;
  params.last_name = obj.lastName;
  params.sex = obj.sex;
  params.dob_day = obj.dobDay;
  params.dob_month = obj.dobMonth;
  params.dob_year = obj.dobYear;

  return request(`${URLS.PMH_VERIFY_FIELDS}`, {
    params,
  });
};

//https://prism-api-dev.hli.io/help#subject_get
//search by ID
//{{url}}/api/subject?client_subject_id=123&client_subject_id_type=pn
//search by name
//{{url}}/api/subject?first_name=nadeem&last_name=bulsara&middle_name=xyz
export const getSubject = (clientSubjectId, clientSubjectIdType = "pn", firstName, lastName) => {
  var params = {};
  if (clientSubjectId) params.client_subject_id = clientSubjectId;
  if (clientSubjectIdType) params.client_subject_id_type = clientSubjectIdType;
  if (firstName) params.first_name = firstName;
  if (lastName) params.last_name = lastName;

  return request(`${URLS.SUBJECT}`, {
    params,
  });
};

export const getSubjectOrders = (hli_subject_id, checkDetail = false) => {
  var params = {};

  if (hli_subject_id) {
    params = { hli_subject_id };
    if (checkDetail) {
      params = { hli_subject_id, checkDetail };
    }
  }

  return request(`${URLS.SUBJECT_ORDERS}`, {
    params,
  });
};

export const getMyClientList = (params = {}) => {
  return request(`${URLS.MY_CLIENT_LIST}`, {
    params,
  });
};

export const getMyClientFilterItemList = (params = {}) => {
  return request(`${URLS.MY_CLIENT_FILTER_ITEM_LIST}`, {
    params,
  });
};

/////////////////
// Longevity APIs
/////////////////

//////////////
/// crisscross
export const getHealthCategory = (healthCategoryId, healthCategory) => {
  var params = {};
  if (healthCategoryId) params.health_category_id = healthCategoryId;
  if (healthCategory) params.health_category = healthCategory;

  return request(`${URLS.GET_HEALTH_CATEGORY}`, {
    params,
  });
};

export const addSuggestion = (data) => {
  var payload = {
    data,
  };

  return request(`${URLS.SUGGESTION}`, {
    method: "post",
    data: payload,
  });
};

export const getSuggestion = (hli_subject_id) => {
  var params = {
    hli_subject_id,
  };

  return request(`${URLS.GET_SUGGESTION}`, {
    params,
  });
};

export const addNote = (data) => {
  var payload = {
    data,
  };

  return request(`${URLS.NOTE}`, {
    method: "post",
    data: payload,
  });
};

export const getNote = (hli_subject_id) => {
  var params = {
    hli_subject_id,
  };

  return request(`${URLS.GET_NOTE}`, {
    params,
  });
};

export const addFollowUp = (data) => {
  var payload = {
    data,
  };

  return request(`${URLS.FOLLOW_UP}`, {
    method: "post",
    data: payload,
  });
};

export const getFollowUp = (hli_subject_id) => {
  var params = {
    hli_subject_id,
  };

  return request(`${URLS.GET_FOLLOW_UP}`, {
    params,
  });
};

export const getTestResultDetails = (hli_subject_id) => {
  var params = {
    hli_subject_id,
  };

  return request(`${URLS.GET_TEST_RESULTS_DETAILS}`, {
    params,
  });
};

export const getGeneAnalyses = (hli_subject_id) => {
  var params = {
    hli_subject_id,
  };

  return request(`${URLS.GET_GENE_ANALYSES}`, {
    params,
  });
};

// ROR
export const getRor = (hliSubjectId, category, rorName = "") => {
  var params = {};
  if (hliSubjectId) params.hli_subject_id = hliSubjectId;
  if (category) params.category = category;

  params.ror_name = rorName;

  return request(`${URLS.GET_ROR}`, {
    params,
  });
};
// check in white list
export const checkInWhiteList = (orderReference) => {
  let params = {};
  if (orderReference) params.hli_order_reference = orderReference;

  return request(`${URLS.CHECK_IN_WHITE}`, {
    params,
  });
};

export const getMatrixData = (hliSubjectId) => {
  let params = {};
  if (hliSubjectId) params.hli_subject_id = hliSubjectId;

  return request(`${URLS.GET_MATRIX_DATA}`, {
    params,
  });
};

export const updateMatrixData = (subject_id, data) => {
  const payload = {
    data,
    hli_subject_id: subject_id,
  };

  return request(`${URLS.UPDATE_MATRIX_DATA}`, {
    method: "put",
    data: payload,
  });
};

export const refreshRor = (hliSubjectId, overall, submitted_by, ror_name, hli_order_reference, classification,refresh_range) => {
  var params = {};
  if (hliSubjectId) params.hli_subject_id = hliSubjectId;
  if (submitted_by) params.submitted_by = submitted_by;
  if (typeof overall !== "undefined") params.overall = overall;
  if (refresh_range) params.refresh_range = refresh_range
  params = {
    ...params,
    submitted_by,
    ror_name,
    hli_order_reference,
    classification,
  };

  return request(`${URLS.REFRESH_ROR}`, {
    params,
  });
};

export const getClassification = (hliSubjectId) => {
  var params = {};
  if (hliSubjectId) params.hli_subject_id = hliSubjectId;

  return request(`${URLS.GET_CLASSIFICATION}`, {
    params,
  });
};

export const getRorNameList = (hliSubjectId) => {
  var params = {};
  if (hliSubjectId) params.hli_subject_id = hliSubjectId;

  return request(`${URLS.GET_ROR_NAME}`, {
    params,
  });
};

export const postRorEdit = (hliSubjectId, rorName = "", submitted_by, status) => {
  console.log(hliSubjectId, rorName, submitted_by, status);
  return request(`${URLS.GET_ROR_EDIT}`, {
    method: "post",
    data: {
      data: {
        hli_subject_id: hliSubjectId,
        ror_name: rorName,
        submitted_by: submitted_by,
        status: status, //editing, finish
      },
    },
  });
};

export const getRorEdit = (hliSubjectId, ror_name) => {
  var params = {};
  if (hliSubjectId) params.hli_subject_id = hliSubjectId;
  if (ror_name) params.ror_name = ror_name;

  return request(`${URLS.GET_ROR_EDIT}`, {
    params,
  });
};

export const getMesa = (answer) => {
  var params = {};
  if (answer) params.answer = answer;

  return request(`${URLS.CALC_MESA}`, {
    params,
  });
};
export const getBone = (params) => {
  return request(`${URLS.CALC_MESA}`, {
    params,
  });
};

export const addRor = (hliSubjectId, results, submitted_by, rorName = "", orderReference = "", classification = "") => {
  return request(`${URLS.ADD_ROR}`, {
    method: "post",
    data: {
      data: {
        hli_subject_id: hliSubjectId,
        results: results,
        submitted_by: submitted_by,
        ror_name: rorName,
        hli_order_reference: orderReference,
        classification: classification,
      },
    },
  });
};

export const getRorReportGroups = (reportCategory) => {
  // var authCookie = readCookie("authCookie");
  var authCookie = localStorage.getItem("authCookie");
  var params = {};
  if (reportCategory) params.report_category = reportCategory;
  var headers = {};
  if (authCookie) {
    headers.Authorization = authCookie;
  }
  return axios.get(`${URLS.GET_LONGEVITY_REPORT_GROUPS}`, {
    params,
    headers
  });
};

///////////
/// reports
export const getReportCategory = (reportCategory) => {
  var params = {};
  params.hli_clinician_id = readCookie("hli_clinician_id");
  params.report_category = reportCategory;

  return request(`${URLS.GET_REPORT_CATEGORY}`, {
    params,
  });
};

export const getPatientReport = (patient_id, patient_id_type, orderDate, reportCategory, reportName) => {
  var params = {};
  params.hli_clinician_id = readCookie("hli_clinician_id");
  params.from = "terminal";
  if (patient_id_type === "hli_subject_id") params.hli_subject_id = patient_id;
  else params.patient_id = patient_id;
  if (orderDate) params.order_date = orderDate;
  if (reportCategory) params.report_category = reportCategory;
  if (reportName) params.report_name = reportName;

  return request(`${URLS.GET_PATIENT_REPORT}`, {
    params,
  });
};

// pulse api for messaging channels

export const updateMsgChannels = (channels) => {
  var payload = { data: { channels } };

  return request(`${URLS.PUT_UPDATE_MSG_CHANNELS}`, {
    method: "put",
    data: payload,
  });
};

export const deleteMsgChannels = (channel_arns) => {
  var payload = { data: { channel_arns } };

  return request(`${URLS.PUT_DELETE_MSG_CHANNELS}`, {
    method: "put",
    data: payload,
  });
};

export const getMsgChannels = (auth0) => {
  var params = { auth0 };

  return request(`${URLS.GET_MSG_CHANNELS}`, {
    params,
  });
};

// pulse api for messaging users
export const updateMessagingUser = (auth0_id, email, avatar, cognito_id, chime_user_arn, channels, channel) => {
  var payload = {
    data: {
      auth0_id: auth0_id,
      // email:email,
      // avatar:avatar?avatar:'',
      // cognito_id:cognito_id,
      // chime_user_arn:chime_user_arn,
      is_online: true,
    },
  };
  if (email) payload.data.email = email;
  if (avatar) payload.data.avatar = avatar;
  if (cognito_id) payload.data.cognito_id = cognito_id;
  if (chime_user_arn) payload.data.chime_user_arn = chime_user_arn;
  if (channels) payload.data.channels = channels;
  if (channel) payload.data.channel = channel;

  console.log("updateMessagingUser payload: ", payload);

  return request(`${URLS.PUT_UPDATE_MESSAGING_USER}`, {
    method: "put",
    data: payload,
  });
};

export const getMessagingUser = (role_type) => {
  var params = {};
  params.role_type = role_type;

  return request(`${URLS.GET_MESSAGING_USER}`, {
    params,
  });
};

export const findMessagingUser = (cognito_id) => {
  var params = {};
  params.cognito_id = cognito_id;

  return request(`${URLS.GET_FIND_MESSAGING_USER}`, {
    params,
  });
};

// recent and favorites searches

export const addRecentSearch = (data) => {
  var payload = {
    data,
    hli_clinician_id: readCookie("hli_clinician_id"),
  };

  return request(`${URLS.RECENT_SEARCH}`, {
    method: "post",
    data: payload,
  });
};
export const getRecentSearch = (physicianID, searchID, limit) => {
  var params = {};
  params.hli_clinician_id = readCookie("hli_clinician_id");
  params.physician_id = physicianID;
  if (searchID) params.search_id = searchID;
  if (limit) params.limit = limit;

  return request(`${URLS.GET_RECENT_SEARCH}`, {
    params,
  });
};
export const deleteRecentSearch = (physicianID, searchID) => {
  var params = {};
  params.hli_clinician_id = readCookie("hli_clinician_id");
  params.physician_id = physicianID;
  params.search_id = searchID;

  return request(`${URLS.DELETE_RECENT_SEARCH}`, {
    method: "put",
    params,
  });
};

export const addFavoriteSearch = (data) => {
  var payload = {
    data,
    hli_clinician_id: readCookie("hli_clinician_id"),
  };

  return request(`${URLS.FAVORITE_SEARCH}`, {
    method: "post",
    data: payload,
  });
};
export const getFavoriteSearch = (physicianID, searchID, limit) => {
  var params = {};
  params.hli_clinician_id = readCookie("hli_clinician_id");
  params.physician_id = physicianID;
  if (searchID) params.search_id = searchID;
  if (limit) params.limit = limit;

  return request(`${URLS.GET_FAVORITE_SEARCH}`, {
    params,
  });
};
export const deleteFavoriteSearch = (physicianID, searchID) => {
  var params = {};
  params.hli_clinician_id = readCookie("hli_clinician_id");
  params.physician_id = physicianID;
  params.search_id = searchID;

  return request(`${URLS.DELETE_FAVORITE_SEARCH}`, {
    method: "put",
    params,
  });
};

/// orders

export const searchPatientOrders = (data) => {
  var payload = {
    data,
    hli_clinician_id: readCookie("hli_clinician_id"),
  };

  return request(`${URLS.SEARCH_PATIENT_ORDERS}`, {
    method: "post",
    data: payload,
  });
};

// auth0 - old version
// export const searchPatientOrders = (data) => {
//   var auth0Cookie = readCookie("auth0Cookie");
//   var payload = {
//     data,
//     hli_clinician_id: readCookie("hli_clinician_id"),
//   };
//   var headers = {};
//   if (auth0Cookie) {
//     headers.Authorization = auth0Cookie;
//   }
//   return axios.post(`${URLS.SEARCH_PATIENT_ORDERS}`, payload, {
//     headers,
//   });
// };

export const searchPatientOrdersEs = (data) => {
  var payload = {
    data,
    hli_clinician_id: readCookie("hli_clinician_id"),
  };

  return request(`${URLS.SEARCH_PATIENT_ORDERS_ES}`, {
    method: "post",
    data: payload,
  });
};
/// partners

export const partners = (token, hrn) => {
  // return request(`https://prism-api-dev.hli.io/api/partner/P1/S1`,
  //  return request(`https://prism-api-dev.hli.io/api/product?product_code=PR-12003`,
  return request(`https://longevity-information-api-dev.hli.io/api/recent_search`, {
    method: "post",
  });
};

//alt/temp
export const listDocumentsX = (hli_subject_id, vsee_id) => {
  return request(URLS.POST_LIST_DOCUMENTS, {
    method: "post",
    data: {
      hli_subject_id,
      vsee_id,
      from: "terminal",
    },
  });
};

/////////////////////////////////////////////

export const getReportX = (reportId) => {
  return request(`${URLS.POST_REPORT_PDF}`, {
    method: "post",
    data: {
      id: reportId,
    },
  });
};

export const getCriticalValueReport = (reportId) => {
  return request(`${URLS.GET_CRITICAL_VALUE_REPORT}`, {
    method: "get",
  });
};

export const queryStatisticsreport = (statistics_type = "statistics_member", statistics_month = null) => {
  return request(`${URLS.QUERY_STATISTICS_REPROT}`, {
    method: "get",
    params: { statistics_type, statistics_month },
  });
};

export const queryStatisticsreportDetail = (statistics_type = "statistics_member", statistics_month = null) => {
  return request(`${URLS.QUERY_STATISTICS_REPROT_DETAIL}`, {
    method: "get",
    params: { statistics_type, statistics_month },
  });
};

export const queryStatisticsWillOverdue = (statistics_month = null) => {
  return request(`${URLS.QUERY_STATISTICS_WILL_OVERDUE}`, {
    method: "get",
    params: { statistics_month },
  });
};

export const getPmhMedicines = (hli_subject_id) => {
  return request(`${URLS.GET_PMH_MEDICINES}`, {
    method: "get",
    params: { hli_subject_id },
  });
};

export const getConsentFormRecordList = (body = {}) => {
  return request(`${URLS.CONSENT_FORM_RECORD_LIST}`, {
    method: "post",
    data: body,
  });
};

// notifications

export const sendNotifications = async (expoPushToken, title, body, extraData) => {
  return request(`${URLS.POST_SEND_NOTIFICATIONS}`, {
    method: "post",
    data: {
      to: expoPushToken,
      data: extraData,
      title,
      body,
    },
  });
};

/// Federated

async function signOutIfSignedIn() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    // User is signed in
    await Auth.signOut();
    console.log('Chime User signed out successfully');
  } catch (error) {
    if (error.name === 'NotAuthenticatedException') {
      console.log('Chime Amplify User is not signed in');
    } else {
      console.log('Chime Amplify User Error: ', error);
    }
  }
}

export const federatedSignIn = async () => {
  // let local = localStorage.getItem('awsCreds')

  // console.log("ChimeAPI local: ", local)
  // AWS.config.credentials = local
  // Auth.configure({ refreshHandlers: { auth0Domain : readCookie("auth0RefTok") }});
  // Storage.configure({ refreshHandlers: { auth0Domain : readCookie("auth0RefTok") }});

  try {
    Auth.configure({
      refreshHandlers: { auth0Domain: readCookie("auth0RefTok") },
      identityPoolId: localStorage.getItem("identity_pool_id"),
      region: "us-east-1",
    });
  } catch (err) {
    console.log("Chime AmAuth.Auth.configure error");
  }

  try {
    await signOutIfSignedIn(); // await Auth.signOut();
    // to avoid : AuthClass - There is already a signed in user
    let cred = await Auth.federatedSignIn(
      localStorage.getItem("auth0Domain"),
      {
        token: readCookie("auth0Cookie"),
        expires_at: 120 * 60 * 1000 + new Date().getTime(), //was 10000000
      },
      {
        username: localStorage.getItem("userName"),
      }
    );

    try {
      const chime = new Chime({
        region: "us-east-1",
        credentials: cred,
      });
      AWS.config.credentials = cred;
      Auth.configure({ refreshHandlers: { auth0Domain: readCookie("auth0RefTok") } });
      Storage.configure({ refreshHandlers: { auth0Domain: readCookie("auth0RefTok") } });
      localStorage.setItem("awsCreds", JSON.stringify(cred));
      localStorage.setItem("cognito_id", cred.identityId);
      var ucid = localStorage.getItem("umu_cog_id");
      if (!ucid) {
        let appInstanceArn = localStorage.getItem("app_instance_arn");
        console.log("Chime appInstanceArn: ", appInstanceArn);

        var res;
        try {
          // eslint-disable-next-line no-unused-vars
          res = await chime
            .createAppInstanceUser({
              AppInstanceArn: appInstanceArn,
              AppInstanceUserId: cred.identityId,
              Name: localStorage.getItem("userName"),
            })
            .promise();

          // res = await chime.createAppInstanceAdmin(
          //   { AppInstanceAdminArn: appInstanceArn }).promise();
        } catch (err) {
          console.log("Chime createAppInstanceUser err: ", err);
          if (err.toString().includes("already exists")) {
            console.log("Chime user already exists");
            localStorage.setItem("umu_cog_id", localStorage.getItem("identity_pool_id"));
          }
          console.log(err);
        }

        try {
          let umuRes = await updateMessagingUser(
            localStorage.getItem("msg_user_id"),
            localStorage.getItem("lsEmail").toLowerCase(),
            localStorage.getItem("img_path"),
            cred.identityId,
            appInstanceArn + "/user/" + cred.identityId
          );

          if (umuRes.data.message === "Updated logined user's Chime Id" && umuRes.data.updated.cognito_id) {
            console.log("Chime updateMessagingUser - Updated logined user's Chime Id");
            localStorage.setItem("umu_cog_id", umuRes.data.updated.cognito_id);
          } else {
            console.log("Chime updating messaging user response failed");
          }
        } catch (e) {
          console.log("Chime error updating messaging user", e);
        }
      }
    } catch (err) {
      console.log("Chime federatedSignIn err: ", err);
    }
  } catch (err) {
    console.log("Chime Auth signOut err: ", err);
  }
};

/// utils

export const domainAPI = DOMAIN;

export const readCookie = (name) => {
  return (
    (name = new RegExp("(?:^|;\\s*)" + ("" + name).replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&") + "=([^;]*)").exec(
      document.cookie
    )) && name[1]
  );
};

export const deleteCookie = (name) => {
  // do a simple pathless delete first.
  document.cookie = name + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT;";
};

export const deleteLocalData = () => {
  // remove individually, so not to remove other website data
  // localStorage.clear(); // will remove other websites local storage
  deleteCookie("auth0TimeLeft");
  deleteCookie("authCookie");
  deleteCookie("auth0Cookie");
  deleteCookie("auth0RefTok");
  deleteCookie("auth0ExpiresAt");
  deleteCookie("hli_clinician_id");
  deleteCookie("hli_subject_id");
  deleteCookie("auth0SessionTime");
  /// profile edit part
  if (localStorage) {
    localStorage.removeItem('session_components');
    /// search, PFCN
    localStorage.removeItem("role");
    /// clients
    localStorage.removeItem("lsClients");
    /// personal
    localStorage.removeItem("email");
    localStorage.removeItem("clinic_assigned");
    localStorage.removeItem("first_name"); // set at edit profile
    localStorage.removeItem("middle_name"); // set at edit profile
    localStorage.removeItem("last_name"); // set at edit profile
    localStorage.removeItem("area_code");
    localStorage.removeItem("contact_number");
    localStorage.removeItem("address_line1");
    localStorage.removeItem("address_line2");
    localStorage.removeItem("city");
    localStorage.removeItem("zip_or_postal");
    localStorage.removeItem("state_province_region");
    localStorage.removeItem("country");
    localStorage.removeItem("img_path");
    localStorage.removeItem("site_code");
    localStorage.removeItem("vsee_room_code");
    /// other
    localStorage.removeItem("user_id");
    localStorage.removeItem("report_category");
    localStorage.removeItem("blood_results_search_terms");
    localStorage.removeItem("blood_labs");
    localStorage.removeItem("lab_results");
    localStorage.removeItem("imaging_search_terms");
    localStorage.removeItem("imaging");
    localStorage.removeItem("imaging_results");
    localStorage.removeItem("hli_clinician");
    localStorage.removeItem("vsee_token");
    localStorage.removeItem("lsEmail");
    localStorage.removeItem("auth0Domain");
    //DP
    localStorage.removeItem("metabolic_suggestions");
    localStorage.removeItem("metabolic_notes");
    localStorage.removeItem("metabolic_follow_ups");
    localStorage.removeItem("cardiovascular_suggestions");
    localStorage.removeItem("cardiovascular_notes");
    localStorage.removeItem("cardiovascular_follow_ups");
    localStorage.removeItem("brain_suggestions");
    localStorage.removeItem("brain_notes");
    localStorage.removeItem("brain_follow_ups");

    //Messaging
    localStorage.removeItem("cognito_id");
    localStorage.removeItem("userName");
    localStorage.removeItem("umu_cog_id");
    sessionStorage.removeItem("$a:chat_channel");
    // sessionStorage.removeItem('msg_user');
    // sessionStorage.removeItem('msg_user_list');

    //MyForms
    localStorage.removeItem("userFirstName"); // diff from "first_name" above
    localStorage.removeItem("userMiddleName"); // diff from "middle_name" above
    localStorage.removeItem("userLastName"); // diff from "last_name" above
    localStorage.removeItem("userDOB");

    localStorage.removeItem("env");

    localStorage.removeItem("msg_user_id");
    localStorage.removeItem("app_instance_arn");
    localStorage.removeItem("identity_pool_id");

    localStorage.removeItem("phys_access_code");
    localStorage.removeItem("authCookie");

  }
};
