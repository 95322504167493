import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en";
import cn from "./cn";
import { IS_CN } from "../components/RegionBlock/RegionBlock";

const resources = {
  en: {
    translation: en
  },
  cn: {
    translation: cn
  },
};
i18n.use(initReactI18next).init({
  resources,
  lng: IS_CN ? "cn" : "en",
  interpolation: {
    // react already safes from xss
    escapeValue: false,
  },
});

export default i18n;
