import rootReducer from './rootReducer';
import initialState from './initialState'
import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';

////UNCOMMENT TO USE REDUX DEVTOOLS(CHROME EXTENSION)//////
/*
const store = createStore(
    rootReducer,
    initialState,
    compose(
    applyMiddleware(thunkMiddleware),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
);
*/

const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(thunkMiddleware)
);


export default store;