import React, { useEffect } from "react";
import "./Spinner.css";
// import spinner from "../../zsrc/img/spinner.gif";

export default function Spinner({ children, className, style }) {
  useEffect(() => {
    // looks not required (prefixfree's equivalent exists already)
    // const script = document.createElement("script");
    // script.src = "../../zsrc/loading/prefixfree.min.js";
    // script.async = true;
    // script.type = "text/babel";
    // document.body.appendChild(script);

    return () => {};
  }, []);

  return (
    <div className={`spinner-box-page ${className}`} style={style}>
      <div className="spinner-base">
        <div className="spinner-cube"></div>
        <div className="spinner-cube"></div>
        <div className="spinner-cube"></div>
        <div className="spinner-cube"></div>
        <div className="spinner-cube"></div>
        <div className="spinner-cube"></div>
        <div className="spinner-cube"></div>
        <div className="spinner-cube"></div>
        <div className="spinner-cube"></div>
      </div>
    </div>

    // gif version
    // <div className="spinner-box-page">
    //   <div className="spinner-box">
    //     <img src={spinner} />
    //   </div>
    // </div>
  );
}
