import { combineReducers } from 'redux';

import patients from './reducers/patients';
import user from './reducers/user';
import initialState from './initialState';

// This is the main reducer, we combine them into one
const appReducer = combineReducers({
    patients,
    user
});

// If action is logout we reset the state to its initial values
const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT' || action.type === 'USER_LOGIN') {
        state = initialState;
    }

    return appReducer(state, action);
};

export default rootReducer;


