import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import lodash from "lodash";
import store from "./redux/index";
import './languages/IMLocalize'

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard.css?v=1.2.0";
import "./assets/css/demo.css";
import "./assets/css/base.css";
import "./assets/css/atom.css";
import "./assets/css/pe-icon-7-stroke.css";
import {localRegion} from "./api";
import Spinner from "./components/Terminal/Elements/Spinner/Spinner";
const Login = lazy(() => import("./views/Pages/Login.jsx"));
const LoginIn = lazy(() => import("./views/Pages/LoginIn.jsx"));
const LoginCn = lazy(() => import("./views/Pages/LoginCn.jsx"));
const Verify = lazy(() => import("./views/Pages/Verify.jsx"));
const ResetPassword = lazy(() => import("./views/Pages/ResetPassword.jsx"));
const HealthDocuments = lazy(() => import("./views/Portal/components/HealthDocuments/HealthDocuments"));
const Images = lazy(() => import("./views/Pages/Vsee/Images.jsx"));
const Dashboard = lazy(() => import("./layouts/Dashboard/Dashboard.jsx"));
const TerminalDashboard = lazy(() => import("./components/Terminal/TerminalDashboard/TerminalDashboard"));
const Search = lazy(() => import("./components/Terminal/Search/Search"));
const Survey = lazy(() => import("./components/InvitationModal/Survey"));
const BICenter = lazy(() => import("./components/InvitationModal/BICenter"));
const TrackCare = lazy(() => import("./components/InvitationModal/TrackCare"));
const Message = lazy(() => import("./components/Terminal/Chat/Chat"));
const Help = lazy(() => import("./components/Terminal/User/Help/Help"));
const Preview = lazy(() => import("./components/Terminal/TerminalDashboard/UploadReports/Preview"));

const EnrollmentTermsConsent = lazy(() => import("./components/Terminal/Portal/MyForms/EnrollmentTermsConsent/EnrollmentTermsConsent"));
const ResearchConsent = lazy(() => import("./components/Terminal/Portal/MyForms/ResearchConsent/ResearchConsent"));
const MedicalRecordsReleaseForm = lazy(() => import("./components/Terminal/Portal/MyForms/MedicalRecordsReleaseForm/MedicalRecordsReleaseForm"));
const CommunicationAuthorizationForm = lazy(() => import("./components/Terminal/Portal/MyForms/CommunicationAuthorization/CommunicationAuthorizationForm"));
const MriScreeningForm = lazy(() => import("./components/Terminal/Portal/MyForms/MriScreeningForm/MriScreeningForm"));
const WgsConsentForm = lazy(() => import("./components/Terminal/Portal/MyForms/WgsConsentForm/WgsConsentForm"));
const Pmh = lazy(() => import("./components/Terminal/Portal/MyForms/Pmh/Pmh"));
const CallApp = lazy(() => import("./views/Pages/CallApp"));

const Consent = lazy(() => import("./components/Terminal/Portal/Consent/Consent"));

const Unavailable = lazy(() => import("./components/Terminal/User/Unavailable/Unavailable"));
const EditProfile = lazy(() => import("./components/Terminal/User/EditProfile/EditProfile"));
const LogOut = lazy(() => import("./components/Terminal/User/LogOut"));
const OtherOption = lazy(() => import("./components/Terminal/User/OtherOption"));
const CriticalValueTable = lazy(() => import("./components/Terminal/Search/SearchPannel/Components/CriticalValueTable"));
const MessagingProvider = lazy(() => import("./components/Terminal/Chat/providers/ChatMessagesProvider"));

const MyFormsMob = lazy(() => import("./components/Terminal/Portal/MyForms/MyFormsMob"));

// New landing page for general practitioner
const WorkstationMyClient = lazy(() => import("./components/Terminal/Workstation/MyClient/MyClient"));

// Statistics Dashboard
const StatisticsDashboard = lazy(() => import('./components/Terminal/DataStatistics/Dashboard/StatisticsDashboard'));
const LabsIsolateExport = lazy(() => import('./components/Terminal/TerminalDashboard/PhysicianFeedback/LabsIsolateExport/index'));

// Past Medical History", "Authorization to Obtain Health Information" and "MRI Screening Form"
// https://portal-test.healthnucleus.com/#/vsee-documents/ccpreview%2b60a2b3ce789c489781186d7464457b1f
const REGION =
  lodash.get(window, "location.hostname", "") === "localhost"
    ? localRegion
    : "us-west-2";

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact redirect path="/" to="/" component={REGION === 'cn-northwest-1'? LoginCn: Login} />
          {/* <Route exact redirect path="/007" component={REGION === 'cn-northwest-1'? LoginCn: LoginIn} /> */}
          <Route exact path="/verify/:uuid" component={Verify} />
          <Route exact path="/reset-password/:code" component={ResetPassword} />
          <Route
            exact
            path="/vsee-documents/:patient_vsee_id"
            component={HealthDocuments}
          />
          <Route exact path="/vsee-images/:jwt_token" component={Images} />
          <Route exact path="/enrollment-terms-consent/:patient_vsee_id" component={EnrollmentTermsConsent} />
          <Route exact path="/research-consent/:patient_vsee_id" component={ResearchConsent} />
          <Route exact path="/medical-records-release-form/:patient_vsee_id" component={MedicalRecordsReleaseForm} />
          <Route exact path="/communication-authorization-form/:patient_vsee_id" component={CommunicationAuthorizationForm} />
          <Route exact path="/mri-screening-form/:patient_vsee_id" component={MriScreeningForm} />
          <Route exact path="/wgs-consent-form/:patient_vsee_id" component={WgsConsentForm} />
          <Route exact path="/past-medical-history/:patient_vsee_id" component={Pmh} />
          <Route path="/workstation" component={WorkstationMyClient} />
          <Route path="/statistics-dashboard" component={StatisticsDashboard} />
          <Route path="/search" component={Search} />
          <Route path="/message">
            <MessagingProvider>
                <Message/>
            </MessagingProvider>
          </Route>

          {/* <Route exact path="/portal" component={Search} /> */}
          <Route exact path="/survey" component={Survey} />
          <Route exact path="/BICenter" component={BICenter} />
          <Route exact path="/trackCare" component={TrackCare} onEnter={()=>{document.title='百嘉医疗'}} />
          <Route exact path="/help" component={Help} />
          <Route exact path="/preview" component={Preview} />
          <Route exact path="/preview/:url" component={Preview} />
          <Route exact path="/pmh" component={Pmh} />
          <Route exact path="/mrisf" component={MriScreeningForm} />
          <Route exact path="/medrecrf" component={MedicalRecordsReleaseForm} />
          <Route exact path="/consent" component={Consent} />
          <Route exact path="/unavailable" component={Unavailable} />
          <Route exact path="/user/edit-profile" component={EditProfile} />
          <Route exact path="/user/log-out" component={LogOut} />
          <Route exact path="/user/other-option" component={OtherOption} />
          <Route exact path="/testCritical" component={CriticalValueTable} />
          <Route exact path="/landing/call-app" component={CallApp} />
          <Route exact path="/terminal-dashboard/:id/:id_type/:ror?">
            <MessagingProvider>
                <TerminalDashboard/>
            </MessagingProvider>
          </Route>
          <Route path="/mfpdf" component={MyFormsMob} />
          <Route path="/labs-export" component={LabsIsolateExport} />
          <Route to="/dashboard" render={(props) => <Dashboard {...props} />} />
        </Switch>
      </Suspense>
    </HashRouter>
  </Provider>,
  document.getElementById("root")
);

// Enable Hot Module Replacement, built into CRA(Create React App)
// https://create-react-app.dev/docs/advanced-configuration/, search WDS_SOCKET_ for related configuration
// https://webpack.js.org/concepts/hot-module-replacement/
if (module.hot) {
  module.hot.accept();
}
