import lodash from "lodash";
import { localRegion } from "../../api";

export const REGION =
  lodash.get(window, "location.hostname", "") === "localhost"
    ? localRegion
    : "us-west-2";

export const IS_CN = REGION === "cn-northwest-1";
export const IS_US = REGION === "us-west-2";

/**
 * display when use cn resion
 * can use babel plugin to remove children content code when use us region in future
 * @param {*} props 
 * @returns React.Component
 */
export function CNBlock(props) {
  return IS_CN && props.children;
}

/**
 * display when use us resion
 * can use babel plugin to remove children content code when use cn region in future
 * @param {*} props 
 * @returns React.Component 
 */
export function USBlock(props) {
  return IS_US && props.children;
}
