export default {
  BP: "血压",
  Pulse: "脉搏",
  Wt: "体重",
  Ht: "身高",
  BMI: "BMI",
  Date: "日期",
  "Organs Scanned": "扫描的器官",
  "Normal Range": "参考范围",
  Normal: "正常",
  "Historical Liver Fat Values": "肝脏脂肪 历次数据",
  "Historical Values": "历次数据",
  "Your Liver Fat Values": "您的肝脏脂肪含量",
  "Liver Fat": "肝脏脂肪",
  "Laboratory": "检查项目",
  "Normal Range": "参考范围",
  "Pharmacogenomics": "药物基因组学",
  "Items": "检验项目",
  "alpha_fetoprotein": "甲胎蛋白",
  "pepsaseI": "胃蛋白酶原 I",
  "pepsaseII": "胃蛋白酶原 II",
  "pepsaseI/pepsaseII": "胃蛋白酶原 I / 胃蛋白酶原 II",
  "carbon13": "碳13吹气实验",
  "gastrin17": "胃泌素-17",
  "Coronary Calcium Score": "Coronary Calcium Score 钙化积分",
  "TC": "总胆固醇 TC",
  "TG": "甘油三酯 TG",
  "HDL": "高密度脂蛋白胆固醇 HDL",
  "LDL": "低密度脂蛋白胆固醇 LDL",
  "HCY": "同型半胱氨酸",
  "Your Genomic Clinical Report": "您的基因报告中包括",
};
