const patients = (state = [], action) => {
    switch(action.type){
        case 'UPDATE-PATIENTS-LIST':
            return {
                list: action.patients,
                lastUpdate: (new Date()).getTime()
            };
        case 'UPDATE-PATIENT-ALLREPORTSDOWNLOADED': //find the patient and set AllReportsDownloaded=true
            const list = state.list.map(item => {
                if(action.patient.HliSubjectId === item.HliSubjectId) {
                    return {
                        ...item,
                        AllReportsDownloaded: true                    
                    };
                }
                
                return item;
            });

            return {
                ...state,
                list
            };
        default: 
            return state;
    }
}

export default patients;