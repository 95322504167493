import { request } from "./utils";
import { URLS } from "@/api";

export const carePathQueryPlan = (params) => {
  return carePath("/careplan/query_plan", params);
};
export const carePathGetCareInfo = (params) => {
  return carePath("/careplan/get_care_info", params);
};
export const carePathSelectOrNot = (params, body) => {
  return carePath("/careplan/select_or_not", params, body);
};
export const carePathSaveOrUpdate = (params, body) => {
  return carePath("/careplan/save_or_update", params, body);
};
export const carePathEffectDate = (params, body) => {
  return carePath("/careplan/effect_date", params, body);
};

export const carePathMatchRule = (params, body) => {
  return carePath("/carekit/match_rule", params, body);
};
export const carePathSaveKit = (params, body) => {
  return carePath("/carekit/save_kit", params, body);
};
export const carePathQueryKit = (params) => {
  return carePath("/carekit/query_kit", params);
};

export const carePath = (path, params = {}, body = {}) => {
  return request(`${URLS.CARE_PATH}${path}`, {
    method: "post",
    data: body,
    params,
  });
};
