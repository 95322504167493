import axios from "axios";
import { readCookie,deleteLocalData } from "@/api";
import _ from "lodash";
const localRegion = 'us-west-2'
// const localRegion = 'cn-northwest-1'
const REGION = _.get(window, "location.hostname", "") === "localhost" ? localRegion : "us-west-2";
/**
 * axios method wrapper
 *
 * unified do something before send request, like set authorization header
 * @param {string} url
 * @param {AxiosRequestConfig} options
 * @returns AxiosPromise<any>
 */

export const request = async(url, options) => {
  options = options || {};
  // unified set authorization header
  if (!options.headers) {
    options.headers = {};
  }
  // const authCookie = readCookie("authCookie");
  const authCookie = localStorage.getItem("authCookie");

  if (authCookie) {
    options.headers.Authorization = authCookie;
  }
  try{
    // default is get method
    // console.log('request url: ', url)
    var res = await axios(url, options)
    // console.log('request ', url, ' axios res: ',res)
    return res
  }
  catch(e){
    console.log('api request util error: ', e)
    if(url.indexOf('/getBatchUrl') > -1){
      return 
    }
    if(e.toString().indexOf('401')>=0 ){ //  && REGION === "cn-northwest-1"
      var first = localStorage.getItem("user_id")
      if(first){

        console.log('token expired')

        deleteLocalData()
        localStorage.setItem("api_error", REGION === "cn-northwest-1"?'登陆过期，请重新登陆': "Login expired, please log in again");
        // window.history.pushState = bindEventListener('replaceState');
        var obj = {'expired':'true'}
        if(REGION === "cn-northwest-1"){
          window.history.pushState(obj,'','/')
          setTimeout(()=>{
            window.history.back()
            // if(REGION !== "cn-northwest-1") window.location.reload()
          },10)
        }
        else window.history.replaceState(obj,'','/')


        console.log('token expired', window.history)
        return 'expired'
      }else{
        console.log('util 401 no user_id')
        return '401'
      }
    }
    else{
      return e
    }
  }
};

export const bindEventListener = function(type) {
  const historyEvent = history[type];
  return function() {
      const newEvent = historyEvent.apply(this, arguments);
     const e = new Event(type);
      e.arguments = arguments;
      window.dispatchEvent(e);
      return newEvent;
  };
};
